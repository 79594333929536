// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("/opt/build/repo/src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-travel-js": () => import("/opt/build/repo/src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-wedding-party-js": () => import("/opt/build/repo/src/pages/wedding-party.js" /* webpackChunkName: "component---src-pages-wedding-party-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

